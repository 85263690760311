// const baseUrl = "http://localhost:8000/api/v1";
// const baseUrl = "https://chapperone.us-3.evennode.com/api/v1";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const users = `${baseUrl}/users`;
const organisations = `${baseUrl}/organisations`;
const contracts = `${baseUrl}/contracts`;
const payments = `${baseUrl}/payments`;

function getUrl(type) {
  switch (type) {
    case "users":
      return users;
    case "organisations":
      return organisations;
    case "contracts":
      return contracts;
    case "payments":
      return payments;
    default:
      throw new Error("url case doesn't match any");
  }
}

export default getUrl;
