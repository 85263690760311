import React from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { Container } from "./styles";
import logo from "../../assets/logos/icon_only_small-removebg.png";
import "./contractRegistration.css";

const ContractRegistrationNotFound = () => {
  const history = useHistory();

  return (
    <Container>
      <div>
        <div
          className="logo"
          onClick={() => {
            history.push("/login");
          }}
        >
          <img alt="logo" src={logo} />
          <span style={{ color: "rgb(26, 166, 155)" }}>Chapperone</span>
        </div>
        <h1>You dont have permissions</h1>
        <p style={{ fontSize: "20px", textAlign: "center" }}>
          Your contract data are invalid
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        ></div>
      </div>
    </Container>
  );
};
export default ContractRegistrationNotFound;
