import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";
import logo from "../../assets/logos/icon_only_small-removebg.png";
import "./contractRegistration.css";

const ContractPaymentDone = () => {
  const history = useHistory();

  return (
    <Container>
      <div>
        <div
          className="logo"
          onClick={() => {
            history.push("/login");
          }}
        >
          <img alt="logo" src={logo} />
          <span style={{ color: "rgb(26, 166, 155)" }}>Chapperone</span>
        </div>
        <h1>Thank you for succesful payment</h1>
        <p style={{ fontSize: "20px", textAlign: "center" }}>
          Your contract is currently active
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#01A79B"
          >
            <motion.path
              strokeLinecap="round"
              strokeLinejoin="round"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 0.5 }}
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        </div>
      </div>
    </Container>
  );
};
export default ContractPaymentDone;
