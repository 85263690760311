import Login from "../views/Login/Login";
import Dashboard from "../layouts/dashboard/Dashboard";
import ContractRegistration from "../views/ContractRegistration/ContractRegistration";
import ContractPaymentDone from "../views/ContractRegistration/ContractPaymentDone";
import ContractPaid from "../views/ContractRegistration/ContractPaid";
import ContractPaymentCancel from "../views/ContractRegistration/ContractPaymentCancel";
import ContractRegistrationNotFound from "../views/ContractRegistration/ContractRegistrationNotFound";
import Landing from "../views/Landing/Landing";

var indexRoutes = [
  { path: "/dashboard", name: "Dashboard", component: Dashboard, exact: false },
  { path: "/login", name: "Login", component: Login, exact: true },
  { path: "/", name: "Landing", component: Landing, exact: true },
  {
    path: "/contract_registration/payment_success=true",
    name: "Contracts Payment Done",
    component: ContractPaymentDone,
    exact: true,
  },
  {
    path: "/contract_registration/payment_paid=true",
    name: "Contract Paid",
    component: ContractPaid,
    exact: true,
  },
  {
    path: "/contract_registration/payment_canceled=true",
    name: "Contracts Payment Cancelled",
    component: ContractPaymentCancel,
    exact: true,
  },
  {
    path: "/contract_registration/:contractId",
    name: "Contracts Registration",
    component: ContractRegistration,
    exact: true,
  },
  {
    path: "/contract_registration/",
    name: "Contracts Registration Not Found",
    component: ContractRegistrationNotFound,
    exact: false,
  },
];

export default indexRoutes;
