import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { requests, getUrl } from "../../../globals/requests";
import { useHistory } from "react-router-dom";

// const key ="pk_test_51I5clMAo7qiBizPkr0kJ2MSCWcbRwnIIFvsZ8c0nFIwa2GP40Gq0MpQ3BZUbo0Xkp8XHILzq1KrIEDL6NPMJWN1q00X6sm6E5F";
// should be in env ------------------------------------------
// const key ="pk_test_51MGJE0H0PHCpU9aY9CJ5iViHCRWAlRhxCVuYDVp8aElzaisNm5Zju5FkMu5vVzTZACy7n9mrEmsfUXqj4RkyMuMB00LDTeZL5M";
const key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(key);

const StripeCheckoutHandler = ({ contract }) => {
  const history = useHistory();
  const handleOrder = async (contract) => {
    const url = `${getUrl("payments")}/order`;
    const stripeResp = await requests.post(url, contract);

    const {
      data: { sessionID },
    } = stripeResp;

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionID,
    });
    if (error) {
      console.log(error);
      history.push("/contract_registration/");
    }
  };
  useEffect(() => {
    if (contract._id) {
      handleOrder(contract);
    }
  }, [contract]);
  return null;
};
export default StripeCheckoutHandler;
