import React, { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import { useParams, useHistory } from "react-router-dom";
import { Container } from "./styles";
import { requests, getUrl } from "../../globals/requests";
import logo from "../../assets/logos/icon_only_small-removebg.png";
import "./contractRegistration.css";
import StripeCheckoutHandler from "./components/StripeCheckoutHandler";
import { SpinnerCircular } from "spinners-react";

function ContractRegistration(props) {
  const history = useHistory();
  const { contractId } = useParams();
  const [contract, setContract] = useState({});
  const [errGettingContract, setErrorGettingContract] = useState(false);

  useEffect(() => {
    async function getContract() {
      try {
        setErrorGettingContract(false);

        const url = `${getUrl("contracts")}/${contractId}`;
        const response = await requests.get(url);
        if (response?.data?._id && response?.data?.paid === false) {
          setContract(response.data);
        } else if (response?.data?.paid === true) {
          history.push("/contract_registration/payment_paid=true");
        } else {
          history.push("/contract_registration/");
        }
      } catch (e) {
        setErrorGettingContract(true);
      }
    }

    getContract();
  }, [contractId]);
  useEffect(() => {
    if (errGettingContract) {
      history.push("/contract_registration/");
    }
    return () => {
      setErrorGettingContract(false);
    };
  }, [errGettingContract]);

  return (
    <Container>
      <div>
        <div className="logo">
          <img alt="logo" src={logo} />
          <span style={{ color: "rgb(26, 166, 155)" }}>Chapperone</span>
        </div>
        <div>You will be soon redirect to the payment provider</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <SpinnerCircular secondaryColor="#01A79B" color="blue" />
        </div>

        <StepWizard>
          {contract?._id && <StripeCheckoutHandler contract={contract} />}
        </StepWizard>
      </div>
    </Container>
  );
}

export default ContractRegistration;
